import { CALL_API } from '../middleware';
import {
  GET_LOAN_DATES,
  GET_LOAN_DATES_SUCCESS,
  GET_LOAN_DETAILS,
  GET_LOAN_DETAILS_SUCCESS,
  LoanDetails,
  GET_LENDER_CONFIRMED_STATEMENT,
  GET_LENDER_CONFIRMED_STATEMENT_SUCCESS,
  GET_LENDER_PENDING_STATEMENT,
  GET_LENDER_PENDING_STATEMENT_SUCCESS,
} from '../types/home';

export const getLoans = (body: LoanDetails) => async (dispatch: any) => {
  try {
    const response = await dispatch({
      [CALL_API]: {
        url: `/loans/loans/get-loans?date=${body.date}`,
        method: 'GET',
        types: [GET_LOAN_DETAILS_SUCCESS, GET_LOAN_DETAILS],
      },
    });
    return response.body;
  } catch (error) {
    throw error;
  }
};

export const getLoanDates = () => async (dispatch: any) => {
  try {
    return await dispatch({
      [CALL_API]: {
        url: `/loans/loans/get-loan-dates`,
        method: 'GET',
        types: [GET_LOAN_DATES_SUCCESS, GET_LOAN_DATES],
      },
    });
  } catch (error) {
    throw error;
  }
};

export const getLenderPendingStatements = (body: []) => async (dispatch: any) => {
  try {
    const response = await dispatch({
      [CALL_API]: {
        url: `/payments/statements/cash/lender/pending`,
        method: 'GET',
        types: [GET_LENDER_PENDING_STATEMENT, GET_LENDER_PENDING_STATEMENT_SUCCESS],
      },
    });
    return response.body;
  } catch (error) {
    throw error;
  }
};

export const getLenderConfirmedStatements = (body: []) => async (dispatch: any) => {
  try {
    const response = await dispatch({
      [CALL_API]: {
        url: `/payments/statements/cash/lender`,
        method: 'GET',
        types: [GET_LENDER_CONFIRMED_STATEMENT, GET_LENDER_CONFIRMED_STATEMENT_SUCCESS],
      },
    });
    return response.body;
  } catch (error) {
    throw error;
  }
};
