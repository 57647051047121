export const GET_LOAN_DETAILS = 'GET_LOAN_DETAILS';
export const GET_LOAN_DETAILS_SUCCESS = 'GET_LOAN_DETAILS_SUCCESS';

export const GET_LOAN_DATES = 'GET_LOAN_DATES';
export const GET_LOAN_DATES_SUCCESS = 'GET_LOAN_DATES_SUCCESS';

export const GET_LENDER_CONFIRMED_STATEMENT = 'GET_LENDER_CONFIRMED_STATEMENT';
export const GET_LENDER_CONFIRMED_STATEMENT_SUCCESS = 'GET_LENDER_CONFIRMED_STATEMENT_SUCCESS';

export const GET_LENDER_PENDING_STATEMENT = 'GET_LENDER_PENDING_STATEMENT';
export const GET_LENDER_PENDING_STATEMENT_SUCCESS = 'GET_LENDER_PENDING_STATEMENT_SUCCESS';

interface LoanBody {
  date: string;
}

export type LoanDetails = LoanBody;
