import { Paper } from '@material-ui/core';
import { useEffect, useMemo, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { getLoanDates, getLoans } from '../../redux-store/actions/home';
import moment from 'moment';
import CustomTable, { Row } from '../forms/Table';
import {
  StateProps,
  maturedTableHeaders,
  maturedTableValues,
  maturedConsumerValues,
  LoanResponse,
  LoanType,
  Headers,
  maturedTableHeadersForAdmin,
  maturedTableValuesForAdmin,
  maturedConsumerValuesForBank,
} from './types';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { RootStateType } from '../../redux-store/reducers';
import Header from '../forms/headerBar';
import LenderBar from './lenderBar';
import SideBar from '../forms/SideBar';
import { SIDENAV_ITEMS } from '../../utils/constants';

const additionalHeaders: { [key: string]: Headers[] } = {
  'lender-user': [{ label: '28 days interest', type: 'percent' }],
  'bank-user': [{ label: '2 days interest', type: 'percent' }],
};

function LoanAccounts({ getLoans, getLoanDates, user, ...props }: StateProps) {
  const [data, setLoanData] = useState<LoanResponse>({
    loan_data: [],
    active_loans: [],
    transactions: [],
  });
  const [date, setDate] = useState<string>(moment(new Date()).format('l'));
  const [loanDates, setLoanDates] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const { entity } = useSelector((store: RootStateType) => store.authReducer);

  useEffect(() => {
    setLoading(true);
    async function fetchLoanInfo() {
      try {
        const {
          activeLoans: active_loans = [],
          loanData: loan_data = [],
          transactions = [],
        } = ((await getLoans({ date })) || {}) as unknown as {
          activeLoans: any;
          loanData: any;
          transactions: any;
        };
        setLoanData({ active_loans, loan_data, transactions });
        setLoading(false);
      } catch (err) {
        throw err;
      }
    }
    fetchLoanInfo();
  }, [getLoans, date]);

  // useEffect(() => {
  //   async function fetchLoanDates() {
  //     try {
  //       let response = await getLoanDates();
  //       if (response) {
  //         const loanDates = response.body;
  //         if ((loanDates || []).length) {
  //           setLoanDates(loanDates);
  //           setDate(moment(loanDates[0]).format('l'));
  //         }
  //       }
  //     } catch (err) {
  //       throw err;
  //     }
  //   }
  //   fetchLoanDates();
  // }, [getLoanDates]);

  const maturedLoanConsumers = (index: number, row: any) => {
    if (!maturedLoans.length) {
      return null;
    }
    const { consumers = [] } = maturedLoans[index];

    console.log('consumers')
    console.log(consumers)

    return consumers.map((loan, index) => (
      <Row
        key={index}
        path={user.role == 'admin' ? maturedConsumerValues : maturedConsumerValuesForBank}
        headers={user.role == 'admin' ? [
          ...maturedTableHeadersForAdmin,
          ...(Object.keys(additionalHeaders).includes(user.role)
            ? additionalHeaders[user.role]
            : []),
        ] : maturedTableHeaders}
        row={loan}
        index={index}
      ></Row>
    ));
  };

  const maturedLoans = useMemo((): LoanType[] => {
    return data.loan_data.reduce((prev: any, curr) => {
      return [...prev, ...curr.matured_loans];
    }, []);
  }, [data.loan_data]);

  return (
    <div className="flex_column">
      <Header />
      <div className="flex_row minHeight bgThemeColor">
        <SideBar list={SIDENAV_ITEMS} />
        <div className='loanWrapper'>
          {(user.role === 'lender-user') && <LenderBar entity={entity} />}
          <div className="loan_container">
            <div className="headingText">MATURED LOAN PORTFOLIO</div>
            <Paper className="loanLogSec" square elevation={0}>
              <div>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    autoOk
                    variant="inline"
                    format="MM/dd/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    label="Select Date"
                    value={date}
                    onChange={(date) => setDate(() => moment(date).format('l'))}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </MuiPickersUtilsProvider>
              </div>
              {/* <div className="flex_column" style={{ alignItems: 'flex-end' }}>
          <Select
            placeholder="Date"
            onChange={({ target: { value } }: any) => setDate(value)}
            value={date}
          >
            {loanDates.map((date) => (
              <MenuItem key={date} value={moment(date).format('l')}>
                {moment(date).format('l')}
              </MenuItem>
            ))}
          </Select>
        </div> */}
              {!loading ? (
                <>
                  <CustomTable
                    headers={user.role == 'admin' ? [
                      ...maturedTableHeadersForAdmin,
                      ...(Object.keys(additionalHeaders).includes(user.role)
                        ? additionalHeaders[user.role]
                        : []),
                    ] : maturedTableHeaders}
                    data={maturedLoans.map((loan) => loan.loan_data)}
                    path={user.role == 'admin' ? maturedTableValuesForAdmin : maturedTableValues}
                    _renderGroups={(index: number, row: any) =>
                      maturedLoanConsumers(index, row)
                    }
                    title="Matured Loan Portfolio"
                    enableGroups={true}
                  />
                </>
              ) : (
                <p>{`Loading...`}</p>
              )}
            </Paper>
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect(({ authReducer: { user } }: RootStateType) => ({ user }), {
  getLoans,
  getLoanDates,
})(LoanAccounts);
