export const currency = '$';

export interface StateProps {
  getLoans: Function;
  getLoanDates: Function;
  user: any;
}

export interface CollapseType {
  index: number;
  visible: boolean;
}

export interface TableProps {
  id: string;
  origin_date: string;
  amount: string;
  loan_identifier_id: string;
  principle_payable: string;
  interest_payable: string;
  paid_interest: string;
  paid_priniciple: string;
  interest: string;
  is_over_due: boolean;
  repayment_date: string;
}

export interface SubTable extends TableProps {
  outstanding_balance: string;
  tenure: number;
}

export interface LoanPayload {
  loan_data: TableProps;
  consumers: TableProps[];
}

export interface Transactions {
  amount: string;
  type: string;
  description: string;
  entry_date: string;
  name: string;
  trade_date: string;
}

export interface LoanType extends LoanPayload {
  matured_loans: LoanPayload[];
}

export interface LoanResponse {
  loan_data: LoanType[];
  active_loans: LoanType[];
  transactions: Transactions[];
}

export interface Headers {
  label: string;
  type: 'currency' | 'date' | 'percent' | 'text' | 'loan_type' | undefined | null;
}

export const headers: Headers[] = [
  { label: 'Loan ID', type: 'text' },
  { label: 'Origination Date', type: 'date' },
  { label: 'Maturity Date', type: 'date' },
  { label: 'Outstanding Principal', type: 'currency' },
  { label: 'Assignment Date', type: 'date' },
  { label: 'Holding Period', type: 'text' },
  { label: 'Accrued Interest', type: 'currency' },
];

export const headersForAdmin: Headers[] = [
  // { label: 'Type of Loan', type: 'percent' },
  { label: 'Lender Loan Identifier', type: 'text' },
  { label: 'Origination Date', type: 'date' },
  { label: 'Maturity Date', type: 'date' },
  { label: 'Outstanding Balance', type: 'currency' },
  { label: 'Interest Due', type: 'currency' },

];

export const keys: string[] = [
  'loanId',
  'originDate',
  'maturityDate',
  'amount',
  'assignmentDate',
  'holdingPeriod',
  'paidInterest'
];

export const keysForAdmin: string[] = [
  // 'percentage',
  'loanIdentifierId',
  'originDate',
  'maturityDate',
  'amount',
  'interestPayable',

];

export const maturedTableHeaders: Headers[] = [
  ...headers.slice(0, 4),
  { label: 'Principal Pay Down', type: 'currency' },
  { label: 'Interest', type: 'currency' },
  { label: 'Fee', type: 'currency' },
  { label: 'Holding Period Net $ Return', type: 'currency' },
  { label: 'Annualised Rate of Return', type: 'percent' },
];

export const maturedTableHeadersForAdmin: Headers[] = [
  ...headersForAdmin.slice(0, 4),
  { label: 'Oustanding Principal', type: 'currency' },
  { label: 'Interest Due', type: 'currency' },
  { label: 'Minimum Principal Due', type: 'currency' },
  { label: 'Principal Paid Down', type: 'currency' },
  { label: 'Interest Paid', type: 'currency' },
  { label: 'Overdue Payment', type: 'currency' },
  { label: 'Rolled Principal', type: 'currency' },
  { label: 'Gross Effective Rate', type: 'text' },
  { label: 'Jumna Fee', type: 'currency' },
  { label: 'Net Effective Rate', type: 'text' },
  { label: 'No of Days Overdue', type: 'text' },
  { label: 'Interest On Overdue', type: 'currency' },

];

export const maturedTableValues: string[] = [
  ...keys.slice(0, 4),
  'paidPriniciple',
  'paidInterest',
  'jumnaFee',
  'netRate',
  'annualisedRateOfReturn',
];


export const maturedTableValuesForAdmin: string[] = [
  ...keysForAdmin.slice(0,4),
  'principlePayable',
  'interestPayable',
  'principlePayable',
  'paidPriniciple',
  'paidInterest',
  'totalDue',
  'carryOver',
  'grossRate',
  'jumnaFee',
  'netRate',
  'overdueDays',
  'overdueInterest',

];

export const cosumerValues: string[] = [
  'userLoanIdentifierId',
  'originDate',
  'repaymentDate',
  'outstandingBalance',
  'interest',
];


export const cosumerValuesForBank: string[] = [
  'consumerLoanId',
  'originDate',
  'repaymentDate',
  'outstandingBalance',
  '',
  '',
  'interest',
];

export const maturedConsumerValues: string[] = [
  'userLoanIdentifierId',
  'originDate',
  'repaymentDate',
  'outstandingBalance',
  'principle',
  'interest',
  'payableAmount',
  'paidPriniciple',
  'paidInterest',
  'totalDue',
  'carryOver',
  'grossRate',
  'jumnaFee',
  'netRate',
  'overdueDays',
  'overdueInterest',
];

export const maturedConsumerValuesForBank: string[] = [
  'consumerLoanId',
  'originDate',
  'repaymentDate',
  'outstandingBalance',
  'principle',
  'interest',
  '',
  '',
  '',
];

// export const consumerHeaders: Headers[] = [
//   { label: "Type of Loan", type: "text" },
//   { label: "Loan Identifier", type: "text" },
//   { label: "Origin Date", type: "date" },
//   { label: "Amount", type: "currency" },
//   { label: "Interest", type: "currency" },
//   { label: "Outstanding Balance", type: "currency" },
//   { label: "Tenure", type: "text" },
//   { label: "Min Payable Amount", type: "currency" },
//   { label: "Min Principle Amount", type: "currency" },
//   { label: "Repayment Date", type: "date" },
// ];

export const transactionsHeaders: Headers[] = [
  { label: 'Date', type: 'date' },
  { label: 'Value Date', type: 'date' },
  { label: 'Description', type: 'text' },
  { label: 'Reference No.', type: 'text' },
  { label: 'Credit', type: 'loan_type' },
  { label: 'Debit', type: 'loan_type' },
  { label: 'Balance', type: 'currency' },

];

export const transactionsHeadersForAdmin: Headers[] = [
  { label: 'Date', type: 'date' },
  { label: 'Value Date', type: 'date' },
  { label: 'Amount', type: 'currency' },
  { label: 'Credit', type: 'loan_type' },
  { label: 'Debit', type: 'loan_type' },
  { label: 'Description', type: 'text' },
];

export const transactionsKeys: string[] = [
  'entry_date',
  'trade_date',
  'description',
  '',
  'type',
  'type',
  'amount',
];


export const transactionsKeysForAdmin: string[] = [
  'entry_date',
  'trade_date',
  'amount',
  'type',
  'type',
  'description',
];

export interface PendingStatementProps {
  getLenderPendingStatements: Function;
  user: any;
}

export interface ConfimedStatementProps {
  getLenderConfirmedStatements: Function;
  user: any;
}

export const ConfirmedStatementsHeaders: Headers[] = [
  { label: 'Trade Date', type: 'date' },
  { label: 'Value Date', type: 'date' },
  { label: 'Payment ID', type: 'text' },
  { label: 'Description', type: 'text' },
  { label: 'Type', type: 'text' },
  // { label: 'Txn Status', type: 'text' },
  { label: 'Amount', type: 'currency' },
  { label: 'Balance', type: 'currency' },
];

export const ConfirmedStatementsKeys: string[] = [
  'tradeDate',
  'valueDate',
  'paymentId',
  'description',
  'type',
  'amount',
  'balance',
  // 'txnStatus',
];
