import { Paper } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { getLenderPendingStatements } from '../../redux-store/actions/home';
import CustomTable from '../forms/Table';
import {
ConfirmedStatementsHeaders,
ConfirmedStatementsKeys,
PendingStatementProps,
} from './types';
import { RootStateType } from '../../redux-store/reducers';
import Header from '../forms/headerBar';
import SideBar from '../forms/SideBar';
import { SIDENAV_ITEMS } from '../../utils/constants';
import LenderBar from './lenderBar';
import { useHistory } from 'react-router';

function LoanAccounts({ getLenderPendingStatements, user, ...props }: PendingStatementProps) {
    const [loading,setLoading] = useState<boolean>(false);
    const [statement_data, setData] = useState([]);
    const history = useHistory();

    useEffect(() => {
        setLoading(true);
    async function fetchLoanInfo() {
      try {
        const data:[] = (await getLenderPendingStatements()) || [];
        setData(data);
        setLoading(false);
      } catch (err) {
        throw err;
      }
    }
    fetchLoanInfo();
      }, [getLenderPendingStatements]);

  const { entity } = useSelector((store: RootStateType) => store.authReducer);

  return (
    <div className="flex_column">
      <Header />
      <div className="flex_row minHeight bgThemeColor">
        <SideBar list={SIDENAV_ITEMS} />
        <div className='loanWrapper'>
          {(user.role === 'lender-user') && <LenderBar entity={entity} />}
          <div className="loan_container">
            <div className='backNavContainer'>
            <img
                src="/icons/back_icon.svg"
                alt="Go back"
                style={{marginBottom:18,paddingRight: 10}}
                onClick={() => history.goBack()}
              />
            <div className="headingText">PENDING STATEMENT</div>
            </div>
            <Paper className="loanLogSec" square elevation={0}>
              {!loading ? (
                <>
                 <CustomTable
                  headers={ConfirmedStatementsHeaders}
                  path={ConfirmedStatementsKeys}
                  data={statement_data}
                  // title="Lender Cash Account"
                  />
                </>
              ) : (
                <p>{`Loading...`}</p>
              )}
            </Paper>
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect(({ authReducer: { user } }: RootStateType) => ({ user }), {
    getLenderPendingStatements
})(LoanAccounts);
