import { AppBar, Toolbar } from '@material-ui/core';
import { useEffect, useState } from "react";
import { SIDENAV_ITEMS } from '../../utils/constants';
import { NavItems } from './SideBar';
import React from 'react';
import { getEntities, updateEntityStatus } from '../../redux-store/actions/entity';
import { useDispatch, useSelector } from 'react-redux';
import { Entites } from '../../redux-store/reducers/entity';
import { onLogout } from '../../redux-store/actions/login';

export default function Header() {
  const [show, showOverLay] = useState(false);

  const dispatch = useDispatch();
  // const entityList = useSelector((state: { entites: Entites }) => state.entites);

  // const changeEntityStatus = async (entityId: string, status: boolean) => {
  //   await dispatch(updateEntityStatus(entityId, status));
  // };

  // useEffect(() => {
  //   async function fetch() {
  //     await dispatch(getEntities());
  //   }
  //   fetch();
  // }, []);
  return (
    <div>
      <AppBar color="transparent" className="app_header" position="relative">
        <Toolbar className="headerSec">
          <img alt="logo" src="/icons/unplanklogo.svg" className='unplankLogo'></img>
          <div className="webHeader">
            <p
              onClick={() => dispatch(onLogout())}
              className="logout"
            >
              <img
                src="/icons/logout_inactive.svg"
                alt="logout"
                className='logoutImg'
              />
              Logout
            </p>
          </div>
          <div className="mobileHeader">
            <img
              alt="toggle menu"
              onClick={() => showOverLay(!show)}
              src={show ? '/icons/close_icon.svg' : '/icons/menu_icon.svg'}
              className="menuIcon"
            />
            {show && (
            <div className="menuOverlay">
              <div className="menuNavItems">
                <NavItems list={SIDENAV_ITEMS}/>
                <div
                  onClick={() => dispatch(onLogout())}
                  className="nav_item"
                >
                  <img
                    alt="img"
                    src="/icons/logout_inactive.svg"
                  />
                  <p className='nav_label'>Logout</p>
                </div>
              </div>
            </div>
            )}
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}
