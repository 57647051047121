import { FormControl, FormControlLabel, FormHelperText, Paper, Radio, RadioGroup } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { getLoanDates, getLoans } from '../../redux-store/actions/home';
import moment from 'moment';
import CustomTable from '../forms/Table';
import {
  StateProps,
  transactionsKeys,
  transactionsHeaders,
  LoanResponse,
  transactionsHeadersForAdmin,
  transactionsKeysForAdmin,
} from './types';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { RootStateType } from '../../redux-store/reducers';
import Header from '../forms/headerBar';
import SideBar from '../forms/SideBar';
import { SIDENAV_ITEMS } from '../../utils/constants';
import LenderBar from './lenderBar';
import LenderConfirmedStatements from './lenderConfirmedStatements';
import { Link } from "react-router-dom";

function LoanAccounts({ getLoans, getLoanDates, user, ...props }: StateProps) {
  const [data, setLoanData] = useState<LoanResponse>({
    loan_data: [],
    active_loans: [],
    transactions: [],
  });
  const [date, setDate] = useState<string>(moment(new Date()).format('l'));
  const [loanDates, setLoanDates] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [viewType, setViewType] = useState<string>('Inception-to-date')

  const { entity } = useSelector((store: RootStateType) => store.authReducer);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setViewType(e.target.value)
  }

  useEffect(() => {
    setLoading(true);
    async function fetchLoanInfo() {
      try {
        const {
          activeLoans: active_loans = [],
          loanData: loan_data = [],
          transactions = [],
        } = ((await getLoans({ date })) || {}) as unknown as {
          activeLoans: any;
          loanData: any;
          transactions: any;
        };
        setLoanData({ active_loans, loan_data, transactions });
        setLoading(false);
      } catch (err) {
        throw err;
      }
    }
    fetchLoanInfo();
  }, [getLoans, date]);

  // useEffect(() => {
  //   async function fetchLoanDates() {
  //     try {
  //       let response = await getLoanDates();
  //       if (response) {
  //         const loanDates = response.body;
  //         if ((loanDates || []).length) {
  //           setLoanDates(loanDates);
  //           setDate(moment(loanDates[0]).format('l'));
  //         }
  //       }
  //     } catch (err) {
  //       throw err;
  //     }
  //   }
  //   fetchLoanDates();
  // }, [getLoanDates]);

  useEffect(() => {
    if (viewType === 'Inception-to-date') {
      let formattedDate = moment(new Date()).format('l')
      setDate(formattedDate)
    }
  }, [viewType])

  return (
    <div className="flex_column">
      <Header />
      <div className="flex_row minHeight bgThemeColor">
        <SideBar list={SIDENAV_ITEMS} />
        <div className='loanWrapper'>
          {(user.role === 'lender-user') && <LenderBar entity={entity} />}
          <div className="loan_container">
            <div className='cashAccTextContainer'>
              <div className="headingText">Statement</div>
              <Link
                to={{
                  pathname: "/cash-account/pending-statements",
                }}
                className="pendingStatementLink link_text"
              >
                Pending Statement
              </Link>
            </div>
            <Paper className="loanLogSec" square elevation={0} style={{display:'none'}}>
              <FormControl
              >
                <RadioGroup
                  row
                  id="viewType"
                  name="viewType"
                  value={viewType}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value="Inception-to-date"
                    control={<Radio size="small" color="primary" />}
                    label="Inception-to-date"
                  />
                  <FormControlLabel
                    value="Year-to-date"
                    control={<Radio size="small" color="primary" disabled={true} />}
                    label="Year-to-date"
                  />
                  <FormControlLabel
                    value="Month-to-date"
                    control={<Radio size="small" color="primary" disabled={true} />}
                    label="Month-to-date"
                  />
                </RadioGroup>
              </FormControl>
              {/* <div>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableToolbar
              autoOk
              variant="inline"
              format="MM/dd/yyyy"
              margin="normal"
              id="date-picker-inline"
              label="Select Date"
              value={date}
              onChange={(date) => setDate(() => moment(date).format('l'))}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </MuiPickersUtilsProvider>
        </div> */}
              {/* <div className="flex_column" style={{ alignItems: 'flex-end' }}>
          <Select
            placeholder="Date"
            onChange={({ target: { value } }: any) => setDate(value)}
            value={date}
          >
            {loanDates.map((date) => (
              <MenuItem key={date} value={moment(date).format('l')}>
                {moment(date).format('l')}
              </MenuItem>
            ))}
          </Select>
        </div> */}
              {!loading ? (
                <>
                  <CustomTable
                    headers={user.role == 'admin' ? transactionsHeadersForAdmin : transactionsHeaders}
                    data={data.transactions}
                    path={user.role == 'admin' ? transactionsKeysForAdmin : transactionsKeys}
                    title="Lender Cash Account"
                  />
                </>
              ) : (
                <p>{`Loading...`}</p>
              )}
            </Paper>

             <LenderConfirmedStatements/>
            
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect(({ authReducer: { user } }: RootStateType) => ({ user }), {
  getLoans,
  getLoanDates,
})(LoanAccounts);
