import { Paper } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { getLoanDates, getLoans } from '../../redux-store/actions/home';
import moment from 'moment';
import CustomTable, { Row } from '../forms/Table';
import {
  headers,
  StateProps,
  keys,
  cosumerValues,
  LoanResponse,
  headersForAdmin,
  keysForAdmin,
  cosumerValuesForBank,
} from './types';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { RootStateType } from '../../redux-store/reducers';
import Header from '../forms/headerBar';
import SideBar from '../forms/SideBar';
import { SIDENAV_ITEMS } from '../../utils/constants';
import LenderBar from './lenderBar';
import { useHistory } from 'react-router';

function LoanAccounts({ getLoans, getLoanDates, user, ...props }: StateProps) {
  const [data, setLoanData] = useState<LoanResponse>({
    loan_data: [],
    active_loans: [],
    transactions: [],
  });
  const [date, setDate] = useState<string>(moment(new Date()).format('l'));
  const [loanDates, setLoanDates] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const { entity } = useSelector((store: RootStateType) => store.authReducer);
  const history = useHistory()
  useEffect(() => {
    setLoading(true);
    async function fetchLoanInfo() {
      try {
        const {
          activeLoans: active_loans = [],
          loanData: loan_data = [],
          transactions = [],
        } = ((await getLoans({ date })) || {}) as unknown as {
          activeLoans: any;
          loanData: any;
          transactions: any;
        };
        setLoanData({ active_loans, loan_data, transactions });
        setLoading(false);
      } catch (err) {
        throw err;
      }
    }
    fetchLoanInfo();
  }, [getLoans, date]);

  // useEffect(() => {
  //   async function fetchLoanDates() {
  //     try {
  //       let response = await getLoanDates();
  //       if (response) {
  //         const loanDates = response.body;
  //         if ((loanDates || []).length) {
  //           setLoanDates(loanDates);
  //           setDate(moment(loanDates[0]).format('l'));
  //         }
  //       }
  //     } catch (err) {
  //       throw err;
  //     }
  //   }
  //   fetchLoanDates();
  // }, [getLoanDates]);

  const activeLoanConsumers = (index: number, row: any) => {
    const { consumers } = data.active_loans[index];
    if (!consumers.length) {
      return null;
    }

    console.log('consumers')
    console.log(consumers)

    return consumers.map((loan, index) => (
      <Row
        key={index}
        path={user.role == 'admin' ? cosumerValues : cosumerValuesForBank}
        headers={user.role == 'admin' ? headersForAdmin : headers}
        row={loan}
        index={index}
      ></Row>
    ));
  };

  return (
    <div className="flex_column">
      <Header />
      <div className="flex_row minHeight bgThemeColor">
        <SideBar list={SIDENAV_ITEMS} />
        <div className='loanWrapper'>
          {(user.role === 'lender-user') && <LenderBar entity={entity} />}
          <div className="loan_container">
            <div className="headingText">ACTIVE LOAN PORTFOLIO</div>
            <Paper className="loanLogSec" square elevation={0}>
              <div>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    autoOk
                    variant="inline"
                    format="MM/dd/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    label="Select Date"
                    value={date}
                    onChange={(date) => setDate(() => moment(date).format('l'))}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </MuiPickersUtilsProvider>
              </div>
              {/* date selector */}
              {/* <div className="flex_column" style={{ alignItems: 'flex-end' }}>
            <Select
              placeholder="Date"
              onChange={({ target: { value } }: any) => setDate(value)}
              value={date}
            >
              {loanDates.map((date) => (
                <MenuItem key={date} value={moment(date).format('l')}>
                  {moment(date).format('l')}
                </MenuItem>
              ))}
            </Select>
          </div> */}
              {!loading ? (
                <>
                  <CustomTable
                    headers={user.role == 'admin' ? headersForAdmin : headers}
                    data={data.active_loans.map((item) => item.loan_data)}
                    path={user.role == 'admin' ? keysForAdmin : keys}
                    _renderGroups={(index: number, row: any) => activeLoanConsumers(index, row)}
                    title="Active Loan Portfolio"
                    enableGroups={true}
                  />
                </>
              ) : (
                <p>{`Loading...`}</p>
              )}
            </Paper>
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect(({ authReducer: { user } }: RootStateType) => ({ user }), {
  getLoans,
  getLoanDates,
})(LoanAccounts);
