import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import { Headers } from '../../dashboard/types';
import './index.css';
import moment from 'moment';
import { KeyboardArrowDown, KeyboardArrowRight } from '@material-ui/icons';
import { formatAmount } from '../../../utils/commonFunctions';

interface TableProps {
  headers: Headers[];
  path: string[];
  data: string[] | any;
}

interface TableMainOptional {
  _renderGroups: any | undefined;
  _onRowClick: any | undefined;
  title: string | undefined;
  tags: object | undefined;
  enableGroups: boolean | null | undefined;
}

function isObject(obj: object) {
  return obj !== null && typeof obj === 'object';
}

function getValuesFromObject(path: string[], traverseObject: any): any {
  if (!isObject(traverseObject)) {
    return {};
  }

  const traverseObjectKeys = Object.keys(traverseObject);

  let values = traverseObjectKeys.reduce((prev: any, key: any) => {
    prev = path.includes(key) ? { ...prev, [key]: traverseObject[key] } : prev;
    if (isObject(traverseObject[key])) {
      return { ...prev, ...getValuesFromObject(path, traverseObject[key]) };
    }
    return prev;
  }, {});
  return values;
}

function formatByType(strings: any, value: any, header: Headers): any {
  if (value == undefined) {
    return '_';
  }
  switch (header?.type) {
    case 'currency': {
      return formatAmount({ amount: value });
    }
    case 'date': {
      if (!value) {
        return `--`;
      }
      return `${moment(value).format('l')}`;
    }
    case 'loan_type': {
      // return value;
      return '-';
    }
    case 'percent': {
      return `${value}%`;
    }
    default:
      return `${value}`;
  }
}

export function Row({ row, index, enableGroups = false, collapseRow, ...props }: any) {
  const memoData = useMemo(() => {
    return getValuesFromObject(props.path, row);
  }, [row, props.path]);

  // const [show, setVisibility] = useState<boolean>(false);
  const {show} = row
  
  return (
    <>
      <TableRow onClick={() => (!!props._renderGroups ? collapseRow(index) : null)}>
        {enableGroups ? (
          <TableCell className='cursor'>{show ? <KeyboardArrowDown /> : <KeyboardArrowRight />}</TableCell>
        ) : (
          <TableCell></TableCell>
        )}
        {props.path.map((key: string, index: number) => (
          <TableCell
            key={index}
            align="left"
          >{formatByType`${memoData[key]}${props.headers[index]}`}</TableCell>
        ))}
      </TableRow>
      {show && props._renderGroups(index, row)}
    </>
  );
}

function CustomTable({
  data = [],
  title,
  ...props
}: TableProps & Partial<TableMainOptional>) {
  // console.log(data)
  // console.log(props)
  const [tableData, setTableData] = useState<string[]>(data)

  useEffect(() => {
     setTableData(tableData => tableData.map((row: any) => {return {...row, show: false}}))
  }, [])

  const collapseRow = (id: number) => {
    setTableData(tableData => tableData.map((row: any, index: number) => id === index ? {...row, show: !row?.show}: {...row, show: false}))
  }
  const extraCell = <TableCell></TableCell>;
  return (
    <>
      {/* {title && <label className="sub_text">{title}</label>} */}
      <TableContainer
        className="loanLogTables"
        style={{ marginBottom: 20 }}
        component={Paper}
        elevation={0}
      >
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              {extraCell}
              {props.headers.map((header, index) => (
                <TableCell key={index} align={index === 0 ? undefined : 'left'}>
                  {header.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.map((row: any, index: number) => (
              <Row key={index} index={index} row={row} collapseRow={collapseRow} {...props} />
            ))}
            {!tableData.length && (
              <TableRow>
                <TableCell colSpan={props.headers.length + 1} style={{textAlign:'center'}}>
                  {' '}
                  No data available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default CustomTable;
